body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.editor-wrapper {
  margin: 0 auto;
  max-width: 900px;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

label {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.language-select {
  padding: 8px;
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  border-radius: 5px;
}

.editor-container {
  border: 1px solid #444;
  border-radius: 8px;
  overflow: hidden;
}

.editor-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
}

.file-input {
  background-color: #555;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.editor-btn {
  background-color: #007acc;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.editor-btn:hover {
  background-color: #005fa3;
}

.editor-btn i {
  font-size: 14px;
}

.editor-btn:active {
  background-color: #004785;
}
