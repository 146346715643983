.modal {
    position: fixed; /* Ensure the modal is fixed over the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of everything */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 1000px;
    height: 80%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Position the content relative to modal for proper close button placement */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #888;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  .editor-container {
    height: 100%; /* Ensures the Monaco editor takes up the full height */
  }
  